<script setup lang="ts">
  import { ArrowUpBold } from '@element-plus/icons-vue'
  import { useMainStore } from "@/store/";

  const seoData = await useStaticSeo('/');
  const adsMaster = useAdsMaster();
  const mainStore = useMainStore();
  // 当前用户
  const currentUser = computed(() => {
    return mainStore.currentUser;
  });

  const { advertisementApi, userApi, commodityApi, appraiseApi, collectApi } = useApi()
  const router = useRouter()

  const mobTypeSwiperRef = ref()
  const swiper = useSwiper();

  let mobCommentRef = ref();
  
  let advertisementList = ref([])
  // 轮播图
  let slideshowList = ref([])
  // 移动端轮播图
  let mobileSlidesList = ref([])
  const shapeList = ref([])

  // 商品推荐数据
  let commondityRecommend: any = ref([])
  // 为你推荐
  let commondityForyou: any = ref([])
  // 送礼指南
  let giftsBookList = ref([])
  // 评论列表数据
  let appraiseList = ref([])
    // 评论列表数据
    let totalNum = ref(0)
  // 安全便捷的购物
  let safeConvenientList = ref([])
  // 类型
  let commodityTypeList = ref([
    {
      typeName: 'Ring',
      key: 't-ring',
      list: []
    },
    {
      typeName: 'Necklace',
      key: 't-necklace',
      list: []
    },
    {
      typeName: 'Earrings',
      key: 't-earrings',
      list: []
    },
    {
      typeName: 'Bracelet',
      key: 't-bracelet',
      list: []
    }
  ])
  function formatDate(str) {
    let date = new Date(str) //获取系统时间
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    month = month < 10 ? '0' + month : month
    let day = date.getDate()
    day = day < 10 ? '0' + day : day
    return day + '/' + month + '/' + year
  }
  // 类型购物的顶部
  const activeCate = ref('t-ring')
  // 切换按类型购物
  const changeCate = (tab: any) => {
    if(mobTypeSwiperRef.value) {
      mobTypeSwiperRef.value?.slideTo(tab.index)
    }
  }
  // 商品的收藏  
  let customization = ref([])
  let customizationList = ref([])
  let knowledgeEducation = ref([])
  // 品牌教育
  let brandEducation = ref([])

  let styleList = ref([])
  const category = ref([
    { title: 'Rings', selected: true },
    { title: 'Earing', selected: false },
    { title: 'Bracelets', selected: false },
    { title: 'Necklaces', selected: false },
    { title: 'Necklaces', selected: false},
  ])

const initTypeGlider = () => {
  
  // 按类型选购的五个
  new window.Glider(document.querySelector('.glider_three0'), {
    slidesToShow: 'auto',
    slidesToScroll: 'auto',
    draggable: true,
    exactWidth: true,
    itemWidth: 396,
    responsive: [
      {
        breakpoint: 10,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 200,
          duration: 0.25
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 396,
          duration: 0.25
        }
      }
    ]
  });
  new window.Glider(document.querySelector('.glider_three1'), {
    slidesToShow: 'auto',
    slidesToScroll: 'auto',
    draggable: true,
    exactWidth: true,
    itemWidth: 396,
    responsive: [
      {
        breakpoint: 10,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 200,
          duration: 0.25
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 396,
          duration: 0.25
        }
      }
    ]
  });
  new window.Glider(document.querySelector('.glider_three2'), {
    slidesToShow: 'auto',
    slidesToScroll: 'auto',
    draggable: true,
    exactWidth: true,
    itemWidth: 396,
    responsive: [
      {
        breakpoint: 10,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 200,
          duration: 0.25
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 396,
          duration: 0.25
        }
      }
    ]
  });
  new window.Glider(document.querySelector('.glider_three3'), {
    slidesToShow: 'auto',
    slidesToScroll: 'auto',
    draggable: true,
    exactWidth: true,
    itemWidth: 396,
    responsive: [
      {
        breakpoint: 10,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 200,
          duration: 0.25
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 396,
          duration: 0.25
        }
      }
    ]
  });
  new window.Glider(document.querySelector('.glider_five'), {
    slidesToShow: 'auto',
    slidesToScroll: 'auto',
    arrows: {
      prev: '.pre_five',
      next: '.next_five'
    },
    draggable: true,
    exactWidth: true,
    itemWidth: 396,
    responsive: [
      {
        breakpoint: 10,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 200,
          duration: 0.25
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          itemWidth: 396,
          duration: 0.25
        }
      }
    ]
  });

// 按形状选购订婚戒指
new window.Glider(document.querySelector('.glider'), {
  slidesToShow: 'auto',
  slidesToScroll: 'auto',
  draggable: true,
  exactWidth: true,
  itemWidth: 156,
  responsive: [
    {
      breakpoint: 10,
      settings: {
        slidesToShow: 'auto',
        slidesToScroll: 'auto',
        itemWidth: 54,
        duration: 0.25
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 'auto',
        slidesToScroll: 'auto',
        itemWidth: 156,
        duration: 0.25
      }
    }
  ]
});
// 按风格选购
new window.Glider(document.querySelector('.glider_style'), {
  slidesToShow: 'auto',
  slidesToScroll: 'auto',
  arrows: {
    prev: '.pre_style',
    next: '.next_style'
  },
  draggable: true,
  itemWidth: 396,
  exactWidth: true,
  responsive: [
    {
      breakpoint: 10,
      settings: {
        slidesToShow: 'auto',
        slidesToScroll: 'auto',
        itemWidth: 200,
        duration: 0.25
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 'auto',
        slidesToScroll: 'auto',
        itemWidth: 396,
        duration: 0.25
      }
    }
  ]
});
}

function clearLocalShopcartIfNeed() {
  if ( router.currentRoute.value.query?.redirect_status === 'succeeded' && !currentUser.value) {
    // 由支付成功回调的，清离线购物车
    mainStore.setShoppingList([]);
    // 去掉查询参数
    router.replace('/');
  }
}

  const recomandProducts = ref([
    { title: 'Engagement Rings', isFavorite: false, url: '', img: 'https://cdn.shopify.com/s/files/1/0590/6235/4104/t/45/assets/mb31-1665128549962.png?v=1665128552' },
    { title: 'WEDDING BANDS', isFavorite: false, url: '', img: 'https://cdn.shopify.com/s/files/1/0590/6235/4104/t/45/assets/mb32-1665128563202.png?v=1665128564' },
    { title: 'GEMSTONE RINGS', isFavorite: false, url: '', img: 'https://cdn.shopify.com/s/files/1/0590/6235/4104/t/45/assets/mb34-1665128575316.png?v=1665128576' },
    { title: 'Earrings', isFavorite: false, url: '', img: 'https://cdn.shopify.com/s/files/1/0590/6235/4104/t/45/assets/mb35-1665128590612.png?v=1665128591' },
    { title: 'Necklaces & Bracelets', isFavorite: false, url: '', img: 'https://cdn.shopify.com/s/files/1/0590/6235/4104/t/45/assets/mb33-1665128601889.png?v=1665128603' },
  ])
  
  // function onFavote(item, idx) {
  //   recomandProducts.value.forEach((i, index) => {
  //     if(idx === index){
  //       i.isFavorite = !i.isFavorite
  //     }
  //   })
  // }
  // function onCateClick(item, idx) {
  //   category.value.forEach((i, index) => {

  //     if(idx === index){
  //       if(i.selected === false){
  //         i.selected = !i.selected
  //       }
  //     }else{
  //       i.selected = false
  //     }
  //   })
  // }
  
  const shelvesRecommendTitle = ref('')
  const shelvesRecommendForyouTitle = ref('')

  onMounted(async() => {
    if(window.Glider){
      // console.debug(window.Glider)
     
      // 一行六个半
      // new window.Glider(document.querySelector('.glider_style'), {
      //   slidesToShow: 'auto',
      //   arrows: {
      //     prev: '.pre_style',
      //     next: '.next_style'
      //   },
      //   draggable: true,
      //   itemWidth: 210,
      //   // itemWidth: 246,
      //   exactWidth: true,
      // });



      // 产品推荐
      commodityApi.getCommodityByLibraryJock({
        shelvesType: 'recommend',
        current: 1,
        pageSize: 20
      }).then(res => {
        if(!Array.isArray(res.data?.records)){
          return
        }
        console.log(res.data) 
        commondityRecommend.value = res.data?.records
        shelvesRecommendTitle.value = res.data?.records[0]?.shelvesTitle
        nextTick(() => {
          // 首页产品推荐，一行四个半
          new window.Glider(document.querySelector('.two_glider_style'), {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            arrows: {
              prev: '.pre_two_product',
              next: '.next_two_product'
            },
            draggable: true,
            itemWidth: 396,
            exactWidth: true,
            responsive: [
              {
                // screens greater than >= 775px
                breakpoint: 10,
                settings: {
                  // Set to `auto` and provide item width to adjust to viewport
                  slidesToShow: 'auto',
                  slidesToScroll: 'auto',
                  itemWidth: 200,
                  duration: 0.25
                }
              },
              {
                // screens greater than >= 775px
                breakpoint: 600,
                settings: {
                  // Set to `auto` and provide item width to adjust to viewport
                  slidesToShow: 'auto',
                  slidesToScroll: 'auto',
                  itemWidth: 396,
                  duration: 0.25
                }
              }
            ]
          });
        })
      })

      
      // 为你推荐
      commodityApi.getCommodityByLibraryJock({
        shelvesType: 'recommendForyou',
        current: 1,
        pageSize: 20
      }).then(res => {
        if(!Array.isArray(res.data?.records)){
          return
        }

        commondityForyou.value = res.data?.records
        shelvesRecommendForyouTitle.value = res.data?.records[0]?.shelvesTitle
        nextTick(() => {
          const test = new window.Glider(document.querySelector('.glider_four'), {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            arrows: {
              prev: '.pre_four',
              next: '.next_four'
            },
            duration: 0.3,
            draggable: true,
            exactWidth: true,
            itemWidth: 396,
            responsive: [
              {
                breakpoint: 10,
                settings: {
                  // Set to `auto` and provide item width to adjust to viewpor
                  slidesToShow: 'auto',
                  slidesToScroll: 'auto',
                  itemWidth: 200,
                  duration: 0.25
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 'auto',
                  slidesToScroll: 'auto',
                  itemWidth: 396,
                  duration: 0.25
                }
              }
            ]
          });
        })
      })

      // 获取广告
      advertisementApi.getAdvertisement({advertisementTypeString: 'home_type_jz,home_type_xl,home_type_es,home_type_sz,stone,slideshow,design,customization,customization_test,home_slides_vertical,gift,knowledge_education,brand_education,safe_convenient'}).then(async res => {
        console.log("获取所有广告：", res)
        // 处理类型为 戒指 的
        const jz: [] = res.data.filter((e: any) => {return e.advertisementType === 'home_type_jz'})
        const xl: [] = res.data.filter((e: any) => {return e.advertisementType === 'home_type_xl'})
        const es: [] = res.data.filter((e: any) => {return e.advertisementType === 'home_type_es'})
        const sz: [] = res.data.filter((e: any) => {return e.advertisementType === 'home_type_sz'})
        // const dh: [] = res.data.filter((e: any) => {return e.advertisementType === 'home_type_dh'})
        // 形状
        const xz: [] = res.data.filter((e: any) => {return e.advertisementType === 'stone'})
        const lb: [] = res.data.filter((e: any) => {return e.advertisementType === 'slideshow'})
        const fg: [] = res.data.filter((e: any) => {return e.advertisementType === 'design'})
        const dz: [] = res.data.filter((e: any) => {return e.advertisementType === 'customization'})
        // 定制的流程
        const lc: [] = res.data.filter((e: any) => {return e.advertisementType === 'customization_test'})
        // 移动端轮播
        const mlb: [] = res.data.filter((e: any) => {return e.advertisementType === 'home_slides_vertical'})
		
        // 送礼指南
        const slzn: [] = res.data.filter((e: any) => {return e.advertisementType === 'gift'})

        // 产品知识教育
        knowledgeEducation.value = res.data.filter((e: any) => {return e.advertisementType === 'knowledge_education'})
        // 品牌教育
        brandEducation.value = res.data.filter((e: any) => {return e.advertisementType === 'brand_education'})

        // 安全便捷的购物
        const aqbj: [] = res.data.filter((e: any) => {return e.advertisementType === 'safe_convenient'})
        await sortList(aqbj)
        safeConvenientList.value = aqbj
        console.log("安全便捷：", safeConvenientList.value)

        await sortList(jz)
        await sortList(xl)
        await sortList(es)
        await sortList(sz)
        // await sortList(dh)
        // console.log("送礼指南：", slzn)
        await sortList(slzn)
		
        commodityTypeList.value[0].list = jz
        commodityTypeList.value[1].list = xl
        commodityTypeList.value[2].list = es
        commodityTypeList.value[3].list = sz
        // commodityTypeList.value[4].list = dh

        giftsBookList.value = slzn

		    await sortList(xz)
        shapeList.value = xz
        await sortList(lb)
        slideshowList.value = lb
        await sortList(mlb)
        mobileSlidesList.value = mlb
        customization.value = dz
        await sortList(lc)
        customizationList.value = lc
        await sortList(fg)
        styleList.value = fg

        nextTick(() => {
          initTypeGlider()
        })
      })

      // 获取评论 commentType = 0 好评，50条
      appraiseApi.getAppraisePageNew({current: 1,pageSize: 50, commentType: 0, showFlag: 1}).then(res => {
        console.log("获取评论：", res.data.records)
        const data = res.data.records
        totalNum.value = res.data.total
        data.forEach((element: any) => {
          if (element.commentImage && element.commentImage.length) {
            element.previewList = element.commentImage.map((e: any) => {return fileUrl + e.storageKey})
          }
        });
        appraiseList.value = data
        nextTick(() => {
          new window.Glider(document.querySelector('.comment-line'), {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            arrows: {
              prev: '.pre_comment',
              next: '.next_comment'
            },
            draggable: true,
            exactWidth: true,
            itemWidth: 594,
            responsive: [
              
              {
                // screens greater than >= 775px
                breakpoint: 600,
                settings: {
                  // Set to `auto` and provide item width to adjust to viewport
                  slidesToShow: 'auto',
                  slidesToScroll: 'auto',
                  itemWidth: 594,
                  duration: 0.25
                }
              }
            ]
          });
        })
      
      })
    }
    if (process.client) {
      adsMaster.viewHome();
      clearLocalShopcartIfNeed();
    }
  })

const giftsBookListMobFormat = computed(() => {

  return giftsBookList.value.reduce((pre: any, cur: any) => {
    if (!pre.length || pre[pre.length - 1].length === 2) {
      pre.push([])
    }

    pre[pre.length - 1].push(cur)

    return pre
  }, [])
})

const onSwiperActiveIndexChange = (item: any) => {
  if (!commodityTypeList.value || !commodityTypeList.value.length) return;
  activeCate.value = (commodityTypeList as any).value[item.activeIndex]?.key || ''
};

const onSwiperSohpTpye = (swiper: any) => {
  mobTypeSwiperRef.value = swiper
}

const onSwiperComment = (swiper: any) => {
  mobCommentRef.value = swiper
}

const onClickSwiperSlider = (type: string, ms = 0) => {
  if (!mobCommentRef.value) return;

  if (type === 'next') {
    mobCommentRef.value.slideNext(ms)
  }

  if (type === 'prev') {
    mobCommentRef.value.slidePrev(ms)
  }
}

const sizeMode = inject('sizeMode', 'xxl');
</script>
<template>
  <div v-if="['xs'].includes(sizeMode)" class="homePage">
    <Head>
      <Title>{{ seoData.title }}</Title>
      <Meta v-if="seoData.keywords" name="keywords" :content="seoData.keywords" />
      <Meta v-if="seoData.description" name="description" :content="seoData.description" />
    </Head>
    <div class="homePage-cover">
      <el-carousel trigger="click" :arrow="mobileSlidesList.length > 1" :indicator-position="mobileSlidesList.length > 1 ? 'outside' : 'none'">
        <el-carousel-item
          v-for="(item,index) in mobileSlidesList"
          :key="index"
        >
          <el-image
            v-if="item?.advertisementImage && item?.advertisementImage.length"
            @click="toNewPage(item.interlinkage)"
            style="width: 100%; height: 100%;"
            :src="getImg(item?.advertisementImage[0])"
            fit="cover"
            :lazy="true"
          />
          <!-- fileUrl + item?.advertisementImage[0]?.storageKey -->
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="homePage-sohpEngage">
      <div class="homePage-sohpEngage__title">
        <span>Shop Engagement Rings by Shapes</span>
      </div>

      <div class="homePage-sohpEngage__wrap">
        <div class="homePage-sohpEngage__wrap__inner">
          <div
            v-for="(item, idx) in shapeList"
            :key="'shape' + idx"
            class="wrap-item"
          >
            <div
              class="wrap-item__img"
              v-if="item?.advertisementImage && item?.advertisementImage.length"
              @click="toNewPage(item.interlinkage)"
            >
              <img
                style="width: 0.51rem"
                :src="fileUrl + item?.advertisementImage[0]?.storageKey"
                loading="lazy"
              />
            </div>
            <div class="wrap-item__text">{{ item?.title }}</div>
            <!-- </NuxtLink> -->
          </div>
        </div>
      </div>
    </div>

    <div class="homePage-sohpStyle">
      <div class="homePage-sohpStyle__title">
        <span>Shop By Style</span>
      </div>

      <div class="homePage-sohpStyle__wrap">
        <div class="homePage-sohpStyle__wrap__inner">
          <div v-for="(item, idx) in styleList" :key="'shape' + idx" class="wrap-item">
            <div
              class="wrap-item__img"
              @click="toNewPage(item.interlinkage)"
              v-if="item?.advertisementImage && item?.advertisementImage.length"
            >
              <el-image 
                :src="fileUrl + item?.advertisementImage[0]?.storageKey"
                :srcset="fileUrl + item?.advertisementImage[0]?.storageKey"
                style="width: 100%;height: 100%;"
                :lazy="true"
              />
            </div>
            <div class="wrap-item__text">{{ item?.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="homePage-customCover"
      v-if="customization &&
        customization.length &&
        customization[0]?.advertisementImage &&
        customization[0]?.advertisementImage.length"
    >
        <el-image
          :src="getImg(customization[0]?.advertisementImage[0])"
          :srcset="fileUrl + customization[0]?.advertisementImage[0]?.storageKey"
          :lazy="true"
        />
        <!-- fileUrl + customization[0]?.advertisementImage[0]?.storageKey  -->
    </div>

    <div class="homePage-customText">
      <div class="homePage-customText__title">
        <span>{{customization[0]?.title}}</span>
      </div>

      <div class="homePage-customText__remark">
        <span>{{customization[0]?.remarks}}</span>
      </div>

      <div class="homePage-customText__list">
        <div class="homePage-customText__list__item" v-for="(item, index) in customizationList" :key="index">
          {{ item.title }}
        </div>
      </div>

      <a
        class="homePage-customText__btn"
        href="/collections/engagement-rings"
        target="_self"
      >
        <span>DESIGN NOW</span>
      </a>
    </div>

    <div class="homePage-sohpRecommed">
      <div class="homePage-sohpRecommed__title">
        <div>{{shelvesRecommendTitle}}</div>
        <div class="homePage-sohpRecommed__title__more" @click="toNewPage('/collections/all')">Shop All</div>
      </div>

      <div class="homePage-sohpRecommed__wrap">
        <div class="homePage-sohpRecommed__wrap__inner">
          <div
            v-for="(item, index) in commondityRecommend"
            :key="'style' + index"
            class="wrap-item"
          >
            <ProductListItem
              :data="item"
              :type="1"
              :url="'/products/' + item.urlComponent"
              :index="index"
              showChange
            >
            </ProductListItem>

            <!-- <single-commodity
              :data="item"
              :type="1"
              :url="'/collections/all/products/' + item.url_component"
              :index="index"
              showChange>
            </single-commodity> -->
          </div>
        </div>
      </div>
    </div>

    <div class="homePage-sohpTpye">
      <div class="homePage-sohpTpye__title">
        <span>Shopping By Type</span>
      </div>

      <div class="homePage-sohpTpye__wrap">
        <el-tabs
          v-model="activeCate"
          indicator-position="none"
          :pause-on-hover="false"
          @tab-click="changeCate"
        >
          <el-tab-pane
            v-for="(item, index) in commodityTypeList"
            :key="index"
            :label="item.typeName"
            :name="item.key"
          />
        </el-tabs>

        <Swiper @swiper="onSwiperSohpTpye" @activeIndexChange="onSwiperActiveIndexChange">
          <SwiperSlide
            v-for="(item,index) in commodityTypeList"
            :key="index"
          >
            <div v-if="item.list && item.list.length" class="homePage-sohpTpye__wrap__inner">
              <div
                v-for="(style, idx) in item.list.slice(0, 4)" :key="idx"
                class="wrap-item"
                @click="toNewPage(style.interlinkage)"
              >
                <div class="wrap-item__img">
                  <el-image
                    :src="getImg(style?.advertisementImage[0])"
                    :srcset="getImg(style?.advertisementImage[0])"
                    :lazy="true"
                  />
                </div>
                <div class="wrap-item__text">{{ style?.title }}</div>
              </div>
            </div>
            <div v-else class="homePage-sohpTpye__wrap__inner">
              <el-empty description="description" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>

    <div class="homePage-recommedU">
      <div class="homePage-recommedU__title">
        <div>{{shelvesRecommendForyouTitle}}</div>
        <div class="homePage-recommedU__title__more" @click="toNewPage('/collections/all')">Shop All</div>
      </div>

      <div class="homePage-recommedU__wrap">
        <div class="homePage-recommedU__wrap__inner">
          <div
            v-for="(item, index) in commondityForyou"
            :key="'style' + index"
            class="wrap-item"
          >
            <ProductListItem
              :data="item"
              :type="1"
              :url="'/products/' + item.urlComponent"
              :index="index"
              showChange
            >
            </ProductListItem>
            
            <!-- <single-commodity
              :data="item"
              :type="1"
              :url="'/collections/all/products/' + item.url_component"
              :index="index"
              showChange>
            </single-commodity> -->
          </div>
        </div>
      </div>
    </div>

    <div class="homePage-shopGuide">
      <div class="homePage-shopGuide__title">
        <span>Gift Giving Guidelines</span>
      </div>

      <div class="homePage-shopGuide__wrap">
        <Swiper
          :modules="[SwiperPagination]"
          :loop="false"
          :pagination="{ clickable: true }"
        >
          <SwiperSlide
            v-for="(item,index) in giftsBookListMobFormat"
            :key="index"
          >
            <div class="homePage-shopGuide__wrap__inner">
              <div
                v-for="(style, idx) in item"
                class="wrap-item"
                @click="toNewPage(style.interlinkage)"
              >
                <div class="wrap-item__img">
                  <el-image
                    :src="fileUrl + style?.advertisementImage[0]?.storageKey"
                    :srcset="fileUrl + style?.advertisementImage[0]?.storageKey"
                    :lazy="true"
                  />
                </div>
                <div class="wrap-item__text">{{ style?.title }}</div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>

    <div class="homePage-adtwo" @click="toNewPage(knowledgeEducation[0].interlinkage)">
      <el-image
        :src="fileUrl + knowledgeEducation[0]?.advertisementImage[0]?.storageKey"
        alt="What is Moissanite?"
        title="Moissanite"
        fit="fill"
        :lazy="true"
      />
    </div>

    <div class="homePage-comment">
      <div class="homePage-comment__wrap">
        <div class="wrap-top">
          <div class="wrap-top__title">
            <span>Real Reviews From Real Customers</span>
          </div>

          <div class="wrap-top__actions">
            <div class="wrap-top__actions__msg" @click="toNewPage('/pages/msblue-customer-reviews')">
              <span>See all {{ totalNum }} Reviews</span>
            </div>

            <div class="wrap-top__actions__btns">
              <img src="~/assets/left-icon.png" alt="" srcset="" @click="onClickSwiperSlider('prev', 300)">
              <img src="~/assets/right-icon.png" alt="" srcset="" @click="onClickSwiperSlider('next', 300)">
            </div>
          </div>
        </div>

        <div class="wrap-swiper" v-if="appraiseList.length">
          <Swiper :loop="true" @swiper="onSwiperComment" @activeIndexChange="onSwiperActiveIndexChange">
            <SwiperSlide
              v-for="(item, index) in appraiseList"
              :key="index"
            >
              <div class="wrap-item">
                <div class="wrap-item__top">
                  <img src="/images/starIcon.png" alt="" v-for="item in 5" :key="item">
                  <span>{{ formatDate(item.lastUpdateTime) }}</span>
                </div>
                <div class="wrap-item__title" v-if="!!item.title">{{ item.title }}</div>
                <div class="wrap-item__content" v-if="item.commen">
                  <span>{{ item.comment.slice(0, 100) }}</span>
                  <template v-if="item.commen.length > 100">
                    <span>...</span>
                    <span class="wrap-item__readmore">Read More</span>
                  </template>
                </div>
                <div class="wrap-item__name">{{ item.consumerIdName || '--' }}</div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>

    <div class="homePage-adtwo" @click="toNewPage(brandEducation[0].interlinkage)">
      <el-image
        :src="fileUrl + brandEducation[0]?.advertisementImage[0]?.storageKey"
        alt="What is Moissanite?"
        title="Moissanite"
        fit="fill"
        :lazy="true"
      />
    </div>

    <div class="homePage-depth">
      <div class="homePage-depth__title">
        <span>Safe and Convenient Shopping</span>
      </div>

      <div class="homePage-depth__wrap">
        <div
          class="wrap-item"
          v-for="(item, index) in safeConvenientList"
          :key="index"
          @click="toNewPage(item.interlinkage)"
        >
          <div class="wrap-item__top">
            <img
            class="wrap-item__top__img"
              v-if="item?.advertisementImage && item?.advertisementImage.length"
              :src="fileUrl + item?.advertisementImage[0]?.storageKey"
              alt="" loading="lazy">
            <div class="wrap-item__top__title">{{ item.title || '' }}</div>
          </div>
          <div class="wrap-item__dsc">{{ item.remarks || '' }}</div>
        </div>
      </div>
    </div>
  </div>
  <template v-else>
    <Head>
      <Title>{{ seoData.title }}</Title>
      <Meta v-if="seoData.keywords" name="keywords" :content="seoData.keywords" />
      <Meta v-if="seoData.description" name="description" :content="seoData.description" />
    </Head>
    <section class="site-content">
      <div :class="{'index_goods_img_wrap': true, 'index_goods_img_wrap_onlychild': slideshowList.length === 1}" style="width: 100%">
        <el-carousel trigger="click">
          <el-carousel-item v-for="(item,index) in slideshowList" :key="index">
            <el-image
              v-if="item?.advertisementImage && item?.advertisementImage.length"
              @click="toNewPage(item.interlinkage)"
              class="block-click"
              style="width: 100%; height: 100%"
              :src="fileUrl + item?.advertisementImage[0]?.storageKey"
              fit="cover"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div  class="index_goods_img_mb_wrap" style="width: 100%">
        <el-carousel trigger="click">
          <el-carousel-item v-for="(item,index) in mobileSlidesList" :key="index">
            <el-image
              v-if="item?.advertisementImage && item?.advertisementImage.length"
              @click="toNewPage(item.interlinkage)"
              class="block-click"
              style="width: 100%; height: fit-content;"
              :src="fileUrl + item?.advertisementImage[0]?.storageKey"
              fit="cover"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
        <div class="pc-container-pd product_out_wrap_pc_title bg-gray">
          <!-- 按形状选购订婚戒指 -->
          Shop Engagement Rings by Shapes
        </div>
        <div class="index_page glider-contain_wrap bg-gray pc-container-pd" style="padding-left: calc( 176px - 25px );">
            <div class="glider" style="width: 100%">
              <div v-for="(item, idx) in shapeList" class="img_shape_item_wrap" :key="'shape' + idx">
                  <div class="img_shape_wrap" v-if="item?.advertisementImage && item?.advertisementImage.length">
                    <img
                      class="img_shape block-click"
                      @click="toNewPage(item.interlinkage)"
                      :src="fileUrl + item?.advertisementImage[0]?.storageKey"
                      :srcset="fileUrl + item?.advertisementImage[0]?.storageKey"
                      width="120"
                      height="120">
                  </div>
                  <div class="img_shape_txt">{{ item?.title }}</div>
              </div>
            </div>
        </div>
      <div class="pc-container product_out_wrap_pc_title" style="text-align: left;">
        Shop By Style
        <div class="product_out_title">
            <div class="product_out_title_all"  @click="toNewPage('/collections/all')">Show All</div>
          <div class="product_out_title_btn">
            <button aria-label="Previous" class="glider-prev title_left pre_style"></button>
            <button aria-label="Next" class="glider-next title_right next_style"></button>
          </div>
        </div>
      </div>
      <!-- pc-line-pl -->
      <div class=" pc-container">
        <div class="glider-contain">
          <div class="glider_style" style="width: 100%">
            <div v-for="(item, idx) in styleList" class="style_item_wrap" :key="'style' + idx">
                <div class="img_style_wrap block-click" @click="toNewPage(item.interlinkage)" v-if="item?.advertisementImage && item?.advertisementImage.length">
                  <img class="img_style" :src="fileUrl + item?.advertisementImage[0]?.storageKey" :srcset="fileUrl + item?.advertisementImage[0]?.storageKey" width="380">
                </div>
                <div class="img_style_txt_left">{{ item?.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="design_out_wrap pc-container">
              <div class="design_wrap_img_wrap" v-if="customization && customization.length && customization[0]?.advertisementImage && customization[0]?.advertisementImage.length">
                <a>
                  <img class="design_wrap_img"
                    :src="fileUrl + customization[0]?.advertisementImage[0]?.storageKey"
                    :srcset="fileUrl + customization[0]?.advertisementImage[0]?.storageKey"
                    width="981" height="588" style="aspect-ratio:981 / 588" data-pf-type="Image3" />
                </a>
              </div>
                <div class="design_wrap_card_content">
                  <div class="green_line">
                    <div class="design_wrap_title">{{customization[0]?.title}}</div>
                    <div class="design_wrap_content">
                      {{customization[0]?.remarks}}
                    </div>
                    <div class="design_wrap_list">
                      <div class="design_wrap_list_block" v-for="(item, index) in customizationList" :key="index">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="flex ai_ct">
                      <a class="d_n_btn" href="/collections/engagement-rings" target="_self" data-pf-type="Button" >
                        <span data-pf-type="Text" >DESIGN NOW</span>
                      </a>
                    </div>
                  </div>
                </div>
      </div>
        
     <!-- 产品推荐 -- pc端 -->
     <div class="site-block">
        <div class="pc-container">
          <div class="product_out_wrap_pc_title">
            {{shelvesRecommendTitle}}
            <div class="product_out_title">
              <div class="product_out_title_all" @click="toNewPage('/collections/all')">Show All</div>
            <div class="product_out_title_btn">
              <button aria-label="Previous" class="glider-prev title_left pre_two_product"></button>
              <button aria-label="Next" class="glider-next title_right next_two_product"></button>
            </div>
          </div>
          </div>
          <div class="">
            <div class="glider-contain">
              <div class="two_glider_style" style="width: 100%">
                <div v-for="(item, index) in commondityRecommend" class="style_item_wrap" :key="'style' + index">
                  <ProductListItem
                    :data="item"
                    :type="1"
                    :url="'/products/' + item.urlComponent"
                    :index="index"
                    showChange
                  >
                  </ProductListItem>
                  
                </div>
              </div>
              <button aria-label="Previous" class="glider-prev arr_left pre_two_product"></button>
              <button aria-label="Next" class="glider-next arr_right next_two_product"></button>
            </div>
          </div>
        </div> 
     </div>
      <!-- 按类型购物 -->
      <div class="pc-container">
        <div class="product_out_wrap_pc_title">
          Shopping By Type
          <div class="product_out_title product_out_titleno">
            <div class="product_out_title_all" @click="router.push('/collections/all/' + activeCate)">Show All</div>
          </div>
        </div>
        <div class="style_wrap ">
          <el-tabs v-model="activeCate">
            <el-tab-pane
              v-for="(item, index) in commodityTypeList"
              :key="index"
              :label="item.typeName"
              :name="item.key"
            >
              <div class="glider-contain">
                <div class="glider_category" :class="'glider_three' + index" style="width: 100%">
                  <div v-for="(style, idx) in item.list" class="style_item_wrap" :key="'style' + idx">
                    <NuxtLink class="img_style_wrap block-click" :to="style.interlinkage" v-if="style?.advertisementImage && style?.advertisementImage.length">
                      <img
                        class="img_style"
                        :src="fileUrl + style?.advertisementImage[0]?.storageKey"
                        :srcset="fileUrl + style?.advertisementImage[0]?.storageKey"
                        loading="lazy"
                        width="380" />
                    </NuxtLink>
                    <div class="img_style_txt_left">{{ style?.title }}</div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 为你推荐 -->
      <div class="site-block">
        <div class="pc-container">
          <div class="product_out_wrap_pc_title">
            {{shelvesRecommendForyouTitle}}
            <!-- 为你推荐 -->
            <div class="product_out_title">
                <div class="product_out_title_all"  @click="toNewPage('/collections/all')">Show All</div>
              <div class="product_out_title_btn">
                <button class="glider-prev title_left pre_four"></button>
                <button class="glider-next title_right next_four"></button>
              </div>
            </div>
          </div>
          <div class="style_wrap">
            <div class="glider-contain">
              <div class="glider_four" style="width: 100%">
                <div v-for="(item, index) in commondityForyou" class="style_item_wrap" :key="'style' + index">
                  <ProductListItem
                    :data="item"
                    :type="0"
                    :url="'/products/' + item.urlComponent"
                    :index="index"
                    :showChange="false"
                  >
                  </ProductListItem>
                </div>
              </div>
              <button aria-label="Previous" class="glider-prev arr_left pre_four"></button>
              <button aria-label="Next" class="glider-next arr_right next_four"></button>
            </div>
          </div>
        </div> 
      </div>
      <!-- 送礼指南 -->
      <div class="">
        <div class="pc-container product_out_wrap_pc_title">
          Gift Giving Guidelines
          <div class="product_out_title">
              <div class="product_out_title_all" @click="toNewPage('/collections/all')">Show All</div>
            <div class="product_out_title_btn">
              <button aria-label="Previous" class="glider-prev title_left pre_five"></button>
              <button aria-label="Next" class="glider-next title_right next_five"></button>
            </div>
          </div>
        </div>
        <div class="pc-line-pl style_wrap  pc-container">
          <div class="glider-contain">
            <div class="glider_five" style="width: 100%">
              <div v-for="(item, idx) in giftsBookList" class="style_item_wrap" :key="'item' + idx">
                <NuxtLink
                  class="img_style_wrap block-click"
                  @click="toNewPage(item.interlinkage)"
                  :to="item.interlinkage"
                  v-if="item?.advertisementImage  && item?.advertisementImage.length && item?.advertisementImage[0]?.storageKey">
                  <img class="img_style" :src="fileUrl + item?.advertisementImage[0]?.storageKey" :srcset="fileUrl + item?.advertisementImage[0]?.storageKey" loading="lazy">
                </NuxtLink>
                <div class="img_style_txt_left">{{ item?.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品知识教育 -->
      <div class="full-image">
        <div
          class="index_goods_img_wrap block-click"
          style="width: 100%"
          @click="toNewPage(knowledgeEducation[0].interlinkage)"
          v-if="knowledgeEducation && knowledgeEducation.length && knowledgeEducation[0]?.advertisementImage && knowledgeEducation[0]?.advertisementImage.length"
        >
          <img class="block" style="width: 100%; height: fit-content" :src="fileUrl + knowledgeEducation[0]?.advertisementImage[0]?.storageKey"  width="2480" height="940" alt="What is Moissanite?" title="Moissanite">
        </div>
        <div
          class="index_goods_img_mb_wrap"
          style="width: 100%"
          @click="toNewPage(knowledgeEducation[0].interlinkage)"
          v-if="knowledgeEducation && knowledgeEducation.length && knowledgeEducation[0]?.advertisementImage && knowledgeEducation[0]?.advertisementImage.length"
        >
          <img class="block"  style="width: 100%; height: fit-content" :src="fileUrl + knowledgeEducation[0]?.advertisementImage[0]?.storageKey"  width="720" height="1204" alt="What is Moissanite?" title="Moissanite">
        </div>
      </div>
      <!-- 用户最新热门评价 -->
      <div class="comment-list pc-container">
        <div class="product_out_wrap_pc_title comment-list-title" style="text-align: left;">
          <span>Real Reviews From Real Customers</span>
          <div class="product_out_title">
            <div style="margin-left: -50px;" class="product_out_title_all" @click="toNewPage('/pages/msblue-customer-reviews')">See all {{ totalNum }} Reviews</div>
            <div class="product_out_title_btn comment-list-btn">
              <button aria-label="Previous" class="glider-prev comment-list-btn-icon title_left btn-icon-left pre_comment"></button>
              <button aria-label="Next" class="glider-next comment-list-btn-icon title_right btn-icon-right next_comment"></button>
            </div>
          </div>
        </div>
        <div class="style_wrap">
          <div class="glider-contain">
            <!-- 最后UI评论样式 -->
            <div class="comment-line">
              <div class="comment-line-item" v-for="(item,index) in appraiseList" :key="index">
                <div class="comment-line-type">
                  <img src="/images/starIcon.png" alt="" v-for="item in 5" :key="item">
                  <span style="font-size: 24px; line-height: 20px; ">{{ formatDate(item.lastUpdateTime) }}</span>
                </div>
                <div class="comment-line-title" v-if="!!item.title">{{ item.title }}</div>
                <div class="comment-line-content">{{ item.comment }}</div>
                <div class="comment-line-more" @click="toNewPage('/pages/msblue-customer-reviews')">Read More</div>
                <div class="comment-line-name">{{ item.consumerIdName || '--' }}</div>
              </div>
            </div>
            <!-- 第二版的评论样式 -->
            <!-- <el-carousel trigger="click" :interval="5000">
              <el-carousel-item v-for="(item,index) in appraiseList" :key="index">
                <div class="comment-block">
                  <div class="comment-content">{{ item.comment }}</div>
                  <div v-if="item?.commentImage && item?.commentImage.length" class="scrollbar-line-img">
                    <el-image
                      class="scrollbar-line-img-item"
                      v-for="(img, idx) in item.commentImage"
                      :key="idx"
                      :src="fileUrl + img?.storageKey"
                      fit="cover"
                      :preview-teleported="true"
                      :preview-src-list="item.previewList"
                      :hide-on-click-modal="true"
                    />
                  </div>
                  <div class="comment-type">好评 {{ item.lastUpdateTime }}</div>
                  <div class="comment-name">{{ item.consumerIdName || '--' }}</div>
                </div>
              </el-carousel-item>
            </el-carousel> -->

              <!-- 第一版的评论样式 -->
              <!-- <div v-for="(item, index) in appraiseList" class="style_item_wrap" :key="'appraise' + index">
                <div class="glider-comment-content">{{ item.comment }}</div>
                <el-scrollbar v-if="item?.commentImage && item?.commentImage.length">
                  <div class="scrollbar-line-img">
                    <el-image
                      class="scrollbar-line-img-item"
                      v-for="(img, idx) in item.commentImage"
                      :key="idx"
                      :src="fileUrl + img.storageKey"
                      fit="cover"
                    />
                  </div>
                </el-scrollbar>
                <div class="glider-comment-type">好评 {{ item.lastUpdateTime }}</div>
                <div class="glider-comment-name">{{ item.consumerIdName || '--' }}</div>
              </div> -->
          </div>
        </div>
        <!-- <div class="comment-list-bottom">
          <p class="text-click" @click="toNewPage('/comment')">查看全部</p>
        </div> -->
      </div>

      <!-- 品牌教育 --- 广告配置 -->
      <div class="full-image">
        <div
          v-if="brandEducation && brandEducation.length && brandEducation[0]?.advertisementImage && brandEducation[0]?.advertisementImage.length"
          class="index_goods_img_wrap block-click"
          style="width: 100%"
          @click="toNewPage(brandEducation[0].interlinkage)"
        >
          <img class="block" style="width: 100%; height: fit-content" :src="fileUrl + brandEducation[0]?.advertisementImage[0]?.storageKey"  width="2480" height="940" alt="What is Moissanite?" title="Moissanite">
        </div>
        <div
          v-if="brandEducation && brandEducation.length && brandEducation[0]?.advertisementImage && brandEducation[0]?.advertisementImage.length"
          class="index_goods_img_mb_wrap block-click"
          style="width: 100%"
          @click="toNewPage(brandEducation[0].interlinkage)">
          <img class="block"  style="width: 100%; height: fit-content" :src="fileUrl + brandEducation[0]?.advertisementImage[0]?.storageKey" width="720" height="1204" alt="What is Moissanite?" title="Moissanite">
        </div>
      </div>


      <!-- 安全便捷的购物 -->
      <div class="depth-shopping pc-container">
        <div class="product_out_wrap_pc_title" style="padding-top: 46px;">
          Safe and Convenient Shopping
          <!-- 安全便捷的购物 -->
        </div>
        <el-row :gutter="0">
          <el-col :md="6" :sm="12" :xs="12" v-for="(item, index) in safeConvenientList" :key="index">
            <div class="depth-shopping-block block-click" @click="toNewPage(item.interlinkage)">
              <div class="depth-shopping-block-top">
                <img
                  v-if="item?.advertisementImage && item?.advertisementImage.length"
                  :src="fileUrl + item?.advertisementImage[0]?.storageKey"
                  alt="">
                <div class="depth-shopping-block-title">{{ item.title || '' }}</div>
              </div>
              <div class="depth-shopping-block-msg">{{ item.remarks || '' }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
  </template>
</template>
   
<style scoped lang="scss">
@import '~/assets/styles/default.scss';

.bg-gray {
  background-color: #F8F8F8;
}

.full-image {
  width: 100%;
  margin-top: 92px;
}

.style_item_wrap{
  margin-top: 22px;
  padding-right: 16px;
  position: relative;
}

.glider-contain, .two_glider_style {
  :deep(.glider-track) {
    align-items: flex-start;
  }
}

// :deep {
  :deep(.el-image__placeholder) {
    background: #ffffff;
  }
  :deep(.glider.draggable .glider-slide img) {
    pointer-events: auto;
  }
// }
.img_style_wrap{
  height: fit-content;
  text-align: center;
}
.img_style{
  object-fit: cover;
}
.img_style_txt{
  text-align: center;
}
.img_style_txt_left{
  text-align: start;
  font-size: 14px;
  font-family: gothic;
  color: #000000;
}
.index_goods_img_wrap {
  // :deep {
    :deep(.el-carousel__container) {
      height: calc(720px / 1080px) * 100vh;
      height: auto;
      aspect-ratio: 2560 / 960;
    }
  // }

  &.index_goods_img_wrap_onlychild {
    :deep(.el-carousel__indicators),
    :deep(.el-carousel__arrow) {
      display: none;
    }
  }
}

.index_goods_img_wrap_onlychild .el-carousel__arrow,
.index_goods_img_wrap_onlychild .el-carousel__indicators {
  display: none;
}

.site-block {
  margin-top: 20px;
  // @media (max-width: 1200px) {
  //   box-shadow: 0 0px 5px 5px #f2f2f2;
  // }
}

.comment-line {
  &-item {
    padding-right: 300px;
    @media (max-width: 600px) {
      padding: 0;
      display: flex;
      width: calc(100vw - 100px) !important;
      flex-direction: column;
      align-items: center;
    }
  }
  &-type {
    display: flex;
    align-items: flex-end;
    font-family: gothic;
    font-size: 24px;
    font-style: italic;
    color: #999999;
    margin-top: 24px;
    margin-bottom: 16px;
    img {
      width: 30px;
      height: 28px;
    }
    span {
      margin-left: 16px;
    }
  }
  &-more {
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 14px;
    font-family: gothic;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 7px;
    font-size: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-family: gothicb;
    
  }
  &-content {
    font-size: 14px;
    font-family: gothic;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
  &-name {
    font-size: 18px;
    font-family: gothicb;
    font-weight: bold;
  }
  @media (max-width: 600px) {
    &-type {
      font-size: 12px;
      img {
        width: 15px;
        height: 15px;
      }
    }
    &-content {
      font-size: 12px;
    }
  }
}

.comment-list {
  background-color: #fafafa;
  margin-top: 68px;
  padding: 20px;
  &-title {
    border-bottom: 1px solid #d2d2d2;
    padding-top: 0 !important;
    padding-bottom: 8px;
    .product_out_title {
      top: 8px;
    }
  }
}
.comment-list-bottom {
  display: flex;
  justify-content: center;
  font-size: 14px;
  letter-spacing: 0;
  user-select: none;
  padding: 20px 20px;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

.comment-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.comment-content {
  font-size: 20px;
  font-weight: bold;
  color: #313131;
  margin-bottom: 30px;
}
.comment-type {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 30px;
}
.comment-name {
  font-size: 18px;
  font-weight: 300;
  color: #646464;
  margin-bottom: 30px;
}

.index_goods_img_mb_wrap{
  display: none;
  // :deep {
    :deep(.el-carousel__container) {
      height: auto;
      aspect-ratio: 1080 / 1296;
    }
  // }
}
.index_goods_img_mb, .index_goods_img{
  width: 100%;
}
.glide_wrap{
  height: 177px;
}

.glider-contain_line {
  width: 100%;
  display: flex;
}
.img_shape_item_wrap{
  width: 142px;
}
.img_shape_wrap{
  width: fit-content;
  height: 70px;
  margin: auto;
}
@media (max-width: 600px) {
  .img_shape_wrap{
    width: unset;
    height: unset;
    margin: 0 10px;
  }
  .glider-contain_wrap .glider-slide{
    padding-top: 24px;
    width: 54px !important;
  }
}

.img_shape{
  width: 70px;
  display: block;
  height: 70px;
  margin: 0 auto;
}
.glider-contain_wrap .glider-slide{
  
  padding-top: 58px;
  min-width: auto;
}

.glider-prev{
  left: 0;
}
.glider-next{
  right: 0;
}
.arr_left:before {
    content: '';
    position: absolute;
    background: #fff;
    width: 1px;
    height: 8px;
    top: 28px;
    transform: rotate(-45deg);
}
.arr_left:after {
    content: '';
    position: absolute;
    background: #fff;
    width: 1px;
    height: 8px;
    top: 23px;
    transform: rotate(45deg);
}
.arr_left{
  width: 24px;
  height: 60px;
  position: absolute;
  font-size: 20px;
  top: 50%;
  transform: translate(0%, -50%);
  cursor: pointer;
  -webkit-transition: .35s;
  transition: .35s;
  line-height: 1;

  background-color: #78D9E1;
}

.arr_right:before {
    content: '';
    position: absolute;
    background: #fff;
    width: 1px;
    height: 8px;
    top: 28px;
    transform: rotate(45deg);
}
.arr_right:after {
    content: '';
    position: absolute;
    background: #fff;
    width: 1px;
    height: 8px;
    top: 23px;
    transform: rotate(-45deg);
}
.arr_right{
  width: 24px;
  height: 60px;
  position: absolute;
  font-size: 20px;
  top: 50%;
  transform: translate(0%, -50%);
  cursor: pointer;
  -webkit-transition: .35s;
  transition: .35s;
  line-height: 1;

  background-color: #78D9E1;
}



.title_left:before {
    content: '';
    position: absolute;
    background: #29A2B4;
    width: 1px;
    height: 8px;
    top: 9px;
    transform: rotate(-45deg);
}
.title_left:after {
    content: '';
    position: absolute;
    background: #29A2B4;
    width: 1px;
    height: 8px;
    top: 3px;
    transform: rotate(45deg);
}
.title_left{
  position: absolute;
  left: 96px;
  width: 20px;
  height: 20px;
}

.title_right:before {
    content: '';
    position: absolute;
    background: #29A2B4;
    width: 1px;
    height: 8px;
    top: 9px;
    transform: rotate(45deg);
}
.title_right:after {
    content: '';
    position: absolute;
    background: #29A2B4;
    width: 1px;
    height: 8px;
    top: 3px;
    transform: rotate(-45deg);
}
.title_right{
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
}


.scrollbar-line-img {
  margin-bottom: 30px;
  &-item {
    width: 100px;
    height: 100px;
    margin: 0 20px;
  }
}
  :deep(.el-scrollbar) {
      height: fit-content;
      margin-bottom: 30px;
  }

.glider-next.disabled, .glider-prev.disabled{
  // opacity: 0;
}
.design_out_wrap{
  margin-top: 92px;
  position: relative;
  
  @media (max-width: 600px) {
    margin-top: 36px;
  }
}
.design_wrap_img_wrap{
  width: 63%;
  // border: 1px solid #838383;
  @media (max-width: 1200px) {
    display: none;
  }
}
.design_wrap_img{
  width: 100%;
  height: fit-content;
}
.design_wrap_card{
  width: 100%;
  height: 100%;
  position: relative;
}
.design_wrap_card_content{
  background-color: #fff;
  width: 720px;
  height: 480px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  box-shadow: 0px 0px 4px #e8e8e8;
  @media (max-width: 1500px) {
    width: 70%;
  }
  @media (max-width: 1200px) {
    position: unset;
    width: 100%;
    height: 100%;
    transform: unset;
    aspect-ratio: unset;
  }
}
.green_line{
  // box-shadow: 0px 0px 5px 1px rgb(232 232 232);
  padding: 40px;
  @media (min-width: 1900px) {
    padding: 120px 84px 92px;
  }
  @media (max-width: 1200px) {
    border: 0;
  }
  @media (max-width: 600px) {
    padding: 0 1px 11px;

    .design_wrap_list {
      margin-bottom: 10px;

      &_block {
        width: 50%;
        font-size: 10px;
        margin: 0 0 18px 0;
        text-align: left;
      }
    }
  }
}
.design_wrap_title {
  font-size: 30px;
  font-family: ivyLight;
  margin-bottom: 36px;
  @media (max-width: 600px) {
    font-size: 24px;
    margin-bottom: 24px;
  }
}
.design_wrap_content {
  font-family: gothic;
  font-size: 12px;
  margin-bottom: 32px;
  @media (max-width: 600px) {
    margin-bottom: 28px;
  }
}
.design_wrap_list {
  margin-bottom: 44px;
  display: flex;
  font-family: gothic;
  font-size: 14px;
  @media (max-width: 1200px) {
    color: #D2AA81;
    font-family: gothicb;
    font-weight: bold;
    flex-wrap: wrap;
  }
  &_block {
    text-align: center;
    position: relative;
    margin-right: 30px;
    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-right: 0;
      &::after {
        content: none;
      }
    }
    &::after {
      content: '';
      width: 20px;
      height: 1px;
      background-color: #000000;
      position: absolute;
      top: 50%;
      right: -30px;
      transform: translate(0, -50%);
      @media (max-width: 1200px) {
        content: none;
      }
    }
  }
}
.design_wrap_card_middle_txt{
  padding: 40px 10px;
}
.bottom_wrap{
  padding: 0 10px;
  padding-bottom: 16px;
}
.Divider2{
  font-size: 1px;
  margin-left: 20px;
  margin-right: 20px;
  width: 54px;
  height: 1px;
  background-color: $theme;
}
.sc-hKumaY{
  font-size: 14px;
}
.d_n_btn{
  width: 160px;
  height: 36px;
  border: 1px solid #000000;
  color: #000000;
  text-decoration: none;
  font-family: gothic;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_out_wrap_pc{
  padding: 0 15px;
  width: 100%;
  margin: auto;
  max-width: 1400px;
}

.product_out_title {
  position: absolute;
  right: 0;
  top: 83px;
  font-size: 12px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    top: 42px;
  }
  .product_out_title_all {
    font-size: 18px;
    font-family: gothic;
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  .product_out_title_btn {
    width: 100px;
    height: 20px;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .comment-list-btn{
    width: 44px!important;
    // margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .comment-list-btn-icon{
      width: 14px;
      height: 16px;
    }
    .btn-icon-right{
      right: 0;
    }
    .btn-icon-left{
      left: 110px;
    }
  }
  .glider-next, .glider-prev {
    top: unset;
  }
}
.product_out_titleno {
  top: 83px;
  right: 100px;
  
  @media (max-width: 600px) {
    right: 0px;
    top: 42px;
  }
}
.product_out_wrap_pc_title{
  text-align: center;
  font-size: 30px;
  position: relative;
  padding-top: 69px;
  font-family: ivyLight;
  @media (max-width: 1200px) {
    text-align: start;
  }
  @media (max-width: 600px) {
    font-size: 24px;
    padding-top: 36px;
    padding-right: 80px;
  }
}
.product_out_wrap_ul{
  width: 100%;
}
.product_wrap{
  position: relative;
  width: 20%;
  padding: 15px;
}
.product_img{
  width: 100%;
  height: fit-content;
  min-height: 30px;
}

.product_title{
  color: #100E31;
  text-align: center;
}
.product_color_wrap{
  padding: 1px 0;
}
.bg_f8{
  background-color: #f8f8f8;
  padding: 0 0 !important;
}
.product_color_out_circle{
  margin: 4px;
  border: 1px solid #e2cb9b;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
}
.product_color_inner_circle{
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #e2cb9b;
  
}
.product_price_wrap{
  font-size: 14px;
  color: #100E31;
  text-align: center;
}
.under_line{
  text-decoration: line-through;
}
.favote_icon{
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 20px;
  color: $theme;
}
.icon_size{
  font-size: 30px;
}
.title_cate_wrap{
  padding: 5px 15px;
  font-size: 14px;
  color: #999;
}
.title_cate_wrap_selected{
  font-weight: bold;
  color: #100E31;
}


.depth-shopping {
  margin-top: 60px;
  background-color: #FAFAFA;
  @media (max-width: 1200px) {
    background-color: unset;
  }
  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0;
  }
  &-block {
    margin: 76px 20px;
    &-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-title {
      font-size: 18px;
      font-weight: bold;
      font-family: gothicb;
      margin: 14px auto 18px;
    }
    &-msg {
      font-size: 14px;
      color: #000000;
      font-family: gothic;
      text-align: center;
    }
    @media (max-width: 1200px) {
      margin: 56px 20px;
      &-top {
        display: flex;
        justify-content: flex-start;
        flex-direction: unset;
        border-bottom: 1px solid #333333;
        padding-bottom: 20px;
        margin-bottom: 20px;
        align-items: flex-end;
      }
      &-title {
        display: flex;
        padding-left: 30px;
        font-size: 24px;
        font-weight: bold;
        flex-direction: column;
        align-items: flex-end;
        margin: 0;
      }
      &-msg {
        font-size: 14px;
        text-align: start;
      }
    }
  }
  @media (max-width: 600px) {
    .depth-shopping-block {
      margin: 15px 10px;
    }
    .depth-shopping-block-top {
      img {
        width: 30px;
      }
    }
    .depth-shopping-block-title {
      font-size: 12px;
      padding-left: 10px;
      text-transform: uppercase;
    }
  }
}

// :deep {
  :deep(.el-tabs__item.is-active) {
    color: #29A2B4;
    font-weight: bold;
  }
  :deep(.el-tabs__active-bar) {
    background-color: #29A2B4;
  }
  :deep(.el-tabs__item:hover) {
    color: #29A2B4;
  }
// }

.img_shape_txt{
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 25px;
  text-align: center;
  font-family: gothic;
  color: #000000;
  // transform: scale(0.8);
}
.item_shape_wrap{
  width: 142px;
}
@media (max-width: 1200px) {
  .index_goods_img_wrap{
    display: none;
  }
  .index_goods_img_mb_wrap{
    display: block;
  }
  .img_shape_item_wrap{
    width: 118px;
    padding-top: 53px;
  }
}
.index_page .glider-track{
  margin: 0 auto !important;
}
:deep(.el-tabs__nav-wrap::after){
  opacity: 0;
}
:deep(.el-tabs__active-bar){
  height: 1px;
  bottom: 4px;
}
.comment-line .glider-track{
  margin: initial;
}
</style>

<style scoped lang="scss" src="./index.mobile.scss"></style>